import { get } from 'lodash'
import { clearUnexpectedKeys, convertDate } from './utils'
import { UI } from '../redux/ui/ui.keys'

const errorApi = (name) => new Error(`API Schema error. No key: "${name}"`)

const validateApiSchema = (authInfo) => {
  Object.values(authInfo).forEach((l) => {
    if (l instanceof Error) throw new Error(l)
  })
}

export function extractUserInfo(data) {
  const userInfo = {
    agreedToLawInsuranceRequest: get(
      data,
      'poaData.customerData.agreedToLawInsuranceRequest',
      errorApi('poaData.customerData.agreedToLawInsuranceRequest'),
    ),
    birthCity: get(data, 'poaData.customerData.birthCity', errorApi('poaData.customerData.birthCity')),
    birthDate: get(data, 'poaData.customerData.birthDate', errorApi('poaData.customerData.birthDate')),
    birthName: get(data, 'poaData.customerData.birthName', errorApi('poaData.customerData.birthName')),
    city: get(data, 'poaData.customerData.city', errorApi('poaData.customerData.city')),
    dropRevocationAgreement: get(
      data,
      'poaData.customerData.dropRevocationAgreement',
      errorApi('poaData.customerData.dropRevocationAgreement'),
    ),
    firstName: get(data, 'poaData.customerData.firstName', errorApi('poaData.customerData.firstName')),
    houseNumber: get(data, 'poaData.customerData.houseNumber', errorApi('poaData.customerData.houseNumber')),
    lastName: get(data, 'poaData.customerData.lastName', errorApi('poaData.customerData.lastName')),
    postCode: get(data, 'poaData.customerData.postCode', errorApi('poaData.customerData.postCode')),
    powerOfAttorneyAgreement: get(
      data,
      'poaData.customerData.powerOfAttorneyAgreement',
      errorApi('poaData.customerData.powerOfAttorneyAgreement'),
    ),
    salutation: get(data, 'poaData.customerData.salutation', errorApi('poaData.customerData.salutation')),
    signature: get(data, 'poaData.customerData.signature', errorApi('poaData.customerData.signature')),
    street: get(data, 'poaData.customerData.street', errorApi('poaData.customerData.street')),
    isBirthDateEmpty: !get(data, 'poaData.customerData.birthDate', errorApi('poaData.customerData.birthDate')),
  }
  validateApiSchema(userInfo)
  return userInfo
}

export function extractAuthInfoForVariation(data) {
  const authInfo = {
    allowedPath: get(data, 'allowedPath', errorApi('allowedPath')),
    caseGroupLawyerNames: get(data, 'caseGroupLawyerNames', errorApi('caseGroupLawyerNames')),
    charge: get(data, 'charge', errorApi('charge')),
    firstViewUrl: get(data, 'firstViewUrl', errorApi('firstViewUrl')),
    fullName: get(data, 'fullName', errorApi('fullName')),
    reference: get(data, 'reference', errorApi('reference')),
    total: get(data, 'total', errorApi('total')),
    switchTotal: get(data, 'poaData.switchTotal', errorApi('poaData.switchTotal')),
    switchPoaType: get(data, 'poaData.switchPoaType', errorApi('poaData.switchPoaType')),
    hasInsurance: get(data, 'poaData.hasInsurance', errorApi('poaData.hasInsurance')),
    insuranceType: get(data, 'poaData.insuranceType', errorApi('poaData.insuranceType')),
    latestSentPoaType: get(data, 'poaData.latestSentPoaType', 'poaData.latestSentPoaType'),
    showSwitch: get(data, 'poaData.showSwitch', errorApi('poaData.showSwitch')),
  }
  validateApiSchema(authInfo)
  return authInfo
}

export function extractUiInfoForVariation(data) {
  const uiInfo = {
    [UI.CASE_IDENTIFIER]: get(data, 'caseIdentifier', errorApi('caseIdentifier')),
  }
  validateApiSchema(uiInfo)
  return uiInfo
}

export function extractAuthInfoForAttachment(data) {
  const authInfo = {
    allowedPath: get(data, 'allowedPath', errorApi('allowedPath')),
    attachmentSections: get(data, 'attachmentSections', errorApi('attachmentSections')),
    caseGroupLawyerNames: get(data, 'caseGroupLawyerNames', errorApi('caseGroupLawyerNames')),
    charge: get(data, 'charge', errorApi('charge')),
    firstViewUrl: get(data, 'firstViewUrl', errorApi('firstViewUrl')),
    fullName: get(data, 'fullName', errorApi('fullName')),
    poaData: get(data, 'poaData', errorApi('poaData')),
    reference: get(data, 'reference', errorApi('reference')),
    total: get(data, 'total', errorApi('total')),
    uploadUuid: get(data, 'uploadUuid', errorApi('uploadUuid')),
  }
  validateApiSchema(authInfo)
  return authInfo
}

export function extractUserDataForSign({ data, isSwitched, isAragSwitched, secret, isRemuneration }) {
  const payloadData =  {
    salutation: data.salutation,
    firstName: data.firstName,
    lastName: data.lastName,
    birthName: data.birthName,
    birthDate: convertDate(data.birthDate),
    birthCity: data.birthCity,
    street: data.street,
    houseNumber: data.houseNumber,
    postCode: data.postCode,
    city: data.city,
    signature: data.signature,
    powerOfAttorneyAgreement:
      isRemuneration && data.powerOfAttorneyAgreement === null ? true : data.powerOfAttorneyAgreement,
    agreedToLawInsuranceRequest: data.agreedToLawInsuranceRequest,
    dropRevocationAgreement: data.dropRevocationAgreement,
    remunerationAgreement: data.remunerationAgreement,
    isSwitched,
    isAragSwitched,
    secret,
  }
  for (const key in payloadData) {
    if (typeof payloadData[key] === 'string') {
      payloadData[key] = payloadData[key].trim()
    }
  }
  return payloadData
}

/**
 * Return Action with key and value object as payload or undefined
 * @param {string} type
 * @param {string} key
 * @param {string} value
 * @returns {{payload: ({value, key}|undefined), type}}
 */
export function keyValueAction(type, key, value) {
  return {
    type,
    payload: key !== undefined && value !== undefined ? { key, value } : undefined,
  }
}

export function updateModuleWithExpectedKeys(currentState, initialState, payload) {
  return payload
    ? {
        ...currentState,
        ...clearUnexpectedKeys(payload, Object.keys(initialState)),
      }
    : currentState
}

export function updateModuleKeyWithExpectedKey(currentState, initialState, payload) {
  return payload
    ? clearUnexpectedKeys(
        {
          ...currentState,
          [payload.key]: payload.value,
        },
        Object.keys(initialState),
      )
    : currentState
}
