import React, { useEffect } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import '../../i18n/initialization'
import { Provider, useDispatch } from 'react-redux'
import store from '../../redux/store'
import Modal from '../../components/Modal'
import VariationBPage from '../../pages/variation-b/variation-b.page'
import VariationCPage from '../../pages/variation-c/variation-c.page'
import AttachmentPage from '../../pages/attachment/attachment.page'
import RemunerationPage from '../../pages/remuneration/remuneration.page'
import NotFoundPage from '../../pages/not-found/not-found.page'
import VariationAuthentication from '../../authentication/variation/variation.authentication'
import RemunerationAuthentication from '../../authentication/remuneration/remuneration.authentication'
import AttachmentAuthentication from '../../authentication/attachment/attachment.authentication'
import { GlobalStyles } from '../../styles/global.styles'

// TODO: Find out why error boundary does not catch error

const App = () => {
  useEffect(() => {
    window['useAlternateCta'] = false
  }, [])

  return (
    <Provider store={store}>
      <GlobalStyles />
      <div style={{ display: 'none' }}>TEST</div>
      <div>
        <BrowserRouter>
          <Switch>
            <VariationAuthentication exact path="/">
              <VariationCPage />
            </VariationAuthentication>
            <VariationAuthentication exact path="/a">
              <VariationCPage />
            </VariationAuthentication>
            <VariationAuthentication exact path="/b">
              <VariationBPage />
            </VariationAuthentication>
            <VariationAuthentication exact path="/c">
              <VariationCPage />
            </VariationAuthentication>
            <AttachmentAuthentication exact path="/attachment/:secret">
              <AttachmentPage />
            </AttachmentAuthentication>
            <RemunerationAuthentication exact path="/remuneration/:secret">
              <RemunerationPage />
            </RemunerationAuthentication>
            <Route path="*">
              <NotFoundPage />
            </Route>
          </Switch>
        </BrowserRouter>
        <Modal />
      </div>
    </Provider>
  )
}

export default App
