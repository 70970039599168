import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeAndResetModal, openModal } from '../redux/modal/modal.actions'
import AssignUsJob from '../modals/assign-us-job/assign-us-job.modal'
import AuthoritiesHtml from '../modals/authorities/authorities.modal'
import AuthoritiesRightmartHtml from '../modals/authorities/authorities-rightmart.modal'
import AuthoritiesRuffHtml from '../modals/authorities/authorities-ruff.modal'
import ClientConditionHtml from '../modals/client-conditions/client-conditions.modal'
import ClientConditionRightmartHtml from '../modals/client-conditions/client-conditions-rightmart.modal'
import ClientConditionRuffHtml from '../modals/client-conditions/client-conditions-ruff.modal'
import RevocationHtml from '../modals/revocation/revocation.modal'
import RevocationRightmartHtml from '../modals/revocation/revocation-rightmart.modal'
import RevocationRuffHtml from '../modals/revocation/revocation-ruff.modal'
import CompensationAgreementHtml from '../modals/compensation-agreement/compensation-agreement.modal'
import CompensationAgreementRightmartHtml from '../modals/compensation-agreement/compensation-agreement-rightmart.modal'
import CompensationAgreementRuffHtml from '../modals/compensation-agreement/compensation-agreement-ruff.modal'
import CompensationAgreementLongHtml from '../modals/compensation-agreement-long/compensation-agreement-long.modal'
import CompensationAgreementLongRightmartHtml from '../modals/compensation-agreement-long/compensation-agreement-long-rightmart.modal'
import CompensationAgreementLongRuffHtml from '../modals/compensation-agreement-long/compensation-agreement-long-ruff.modal'
import ImprintHtml from '../modals/imprint/imprint.modal'
import ImprintRightmartHtml from '../modals/imprint/imprint-rightmart.modal'
import ImprintRuffHtml from '../modals/imprint/imprint-ruff.modal'
import InformationHtml from '../modals/information/information.modal'
import InformationRightmartHtml from '../modals/information/information-rightmart.modal'
import InformationRuffHtml from '../modals/information/information-ruff.modal'
import PrivacyHtml from '../modals/privacy-policy/privacy-policy.modal'
import PrivacyRightmartHtml from '../modals/privacy-policy/privacy-policy-rightmart.modal'
import PrivacyRuffHtml from '../modals/privacy-policy/privacy-policy-ruff.modal'
import FuckUpModal from '../modals/fuck-up/fuck-up.modal'
import FormTypeExplanationModal from '../modals/form-type-explanation/form-type-explanation.modal'
import UploadMoreModal from '../modals/upload-more/upload-more.modal'
import UploadSuccessModal from '../modals/upload-success/upload-success.modal'
import UploadMaxFilesModal from '../modals/upload-max-files/upload-max-files.modal'
import FileSizeModal from '../modals/file-size/file-size.modal'
import { selectAuthKey } from '../redux/auth/auth.selectors'
import { AUTH } from '../redux/auth/auth.keys'
import { getValueBasedOnBuyer } from '../utils/utils'

const cancelFocus = (e) => {
  e.preventDefault()
  if (e.target === e.currentTarget) {
    e.stopPropagation()
    e.target.blur()
  }
}

const useModal = () => {
  const dispatch = useDispatch()
  const buyer = useSelector(selectAuthKey(AUTH.BUYER))

  const openAuthoritiesModal = (e) => {
    cancelFocus(e)
    dispatch(
      openModal(
        'authoritiesModal',
        'Befugnisse der Vollmacht',
        getValueBasedOnBuyer(buyer, <AuthoritiesRuffHtml />, <AuthoritiesRightmartHtml />, <AuthoritiesHtml />),
      ),
    )
  }

  const openClientConditionModal = (e) => {
    cancelFocus(e)
    dispatch(
      openModal(
        'clientConditionModal',
        'Mandatsbedingungen',
        getValueBasedOnBuyer(
          buyer,
          <ClientConditionRuffHtml />,
          <ClientConditionRightmartHtml />,
          <ClientConditionHtml />,
        ),
      ),
    )
  }

  const openRevocationModal = (e) => {
    cancelFocus(e)
    dispatch(
      openModal(
        'revocationModal',
        'Widerrufsbelehrung',
        getValueBasedOnBuyer(buyer, <RevocationRuffHtml />, <RevocationRightmartHtml />, <RevocationHtml />),
      ),
    )
  }

  const openCompensationAgreementModal = (e) => {
    cancelFocus(e)
    dispatch(
      openModal(
        'compensationAgreementModal',
        'Vergütungsvereinbarung',
        getValueBasedOnBuyer(
          buyer,
          <CompensationAgreementRuffHtml />,
          <CompensationAgreementRightmartHtml />,
          <CompensationAgreementHtml />,
        ),
      ),
    )
  }

  const openCompensationAgreementLongModal = (e) => {
    cancelFocus(e)
    dispatch(
      openModal(
        'compensationAgreementLongModal',
        'Vergütungsvereinbarung in Bußgeld- und Strafsachen',
        getValueBasedOnBuyer(
          buyer,
          <CompensationAgreementLongRuffHtml />,
          <CompensationAgreementLongRightmartHtml />,
          <CompensationAgreementLongHtml />,
        ),
      ),
    )
  }

  const openImprintModal = (e) => {
    cancelFocus(e)
    dispatch(
      openModal(
        'imprintModal',
        'Impressum',
        getValueBasedOnBuyer(buyer, <ImprintRuffHtml />, <ImprintRightmartHtml />, <ImprintHtml />),
      ),
    )
  }

  const openInformationModal = () => {
    dispatch(
      openModal(
        'informationModal',
        'Allgemeine Informationen bei Verträgen im elektronischen Rechtsverkehr',
        getValueBasedOnBuyer(buyer, <InformationRuffHtml />, <InformationRightmartHtml />, <InformationHtml />),
      ),
    )
  }

  const openPrivacyPolicyModal = (e) => {
    cancelFocus(e)
    dispatch(
      openModal(
        'privacyPolicyModal',
        'Datenschutzerklärung',
        getValueBasedOnBuyer(buyer, <PrivacyRuffHtml />, <PrivacyRightmartHtml />, <PrivacyHtml />),
        'default',
        [],
        null,
        'https://delivery.consentmanager.net/delivery/vendorlist.php?cdid=cf2e41b7b4dc&l=de',
        'cmpvendorlist',
      ),
    )
  }

  const openConfirmAssignUsJob = (action) => {
    dispatch(
      openModal('confirmAssignUsJob', 'Deckungsanfrage starten', <AssignUsJob />, 'default', [
        { type: 'secondary', label: 'Kostenpflichtig beauftragen', callback: action },
      ]),
    )
  }

  const openFuckUpModal = () => {
    dispatch(
      openModal(
        'fuckUpModal',
        'Entschuldigung, das Anlegen des Falls dauert etwas länger als Üblich!',
        <FuckUpModal />,
      ),
    )
  }

  const openFormTypeExplanationModal = (e) => {
    cancelFocus(e)
    e.stopPropagation()
    dispatch(openModal('formTypeExplanationModal', '', <FormTypeExplanationModal />))
  }

  const openFileUpload = (e, buttons) => {
    cancelFocus(e)
    dispatch(openModal('fileUpload', 'Behördenschreiben vollständig?', <UploadMoreModal />, 'alert-narrow', buttons))
  }

  const openFileUploadSuccess = (e, buttons) => {
    cancelFocus(e)
    dispatch(
      openModal(
        'fileUploadSuccess',
        'Vielen Dank, wir haben Ihre Dokumente erhalten!',
        <UploadSuccessModal />,
        'alert',
        buttons,
        'uploadFilesSuccessModal',
      ),
    )
  }

  const openFileMaxSize = (buttons) => {
    dispatch(
      openModal(
        'fileMaxSize',
        'Maximale Seitenanzahl',
        <UploadMaxFilesModal />,
        'alert-narrow',
        buttons,
        'uploadFilesMaxSizeModal',
      ),
    )
  }

  const openFileSize = (rejectedFiles) => {
    dispatch(
      openModal(
        'fileSize',
        rejectedFiles.length === 1 ? 'Datei zu groß' : 'Dateien zu groß',
        <FileSizeModal files={rejectedFiles} />,
        'alert-narrow',
      ),
    )
  }

  return {
    openAuthoritiesModal,
    openClientConditionModal,
    openRevocationModal,
    openCompensationAgreementModal,
    openCompensationAgreementLongModal,
    openImprintModal,
    openInformationModal,
    openPrivacyPolicyModal,
    openConfirmAssignUsJob,
    openFuckUpModal,
    openFormTypeExplanationModal,
    openFileUpload,
    openFileUploadSuccess,
    openFileMaxSize,
    openFileSize,
  }
}

export default useModal
