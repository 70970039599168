import {
  getAuthenticate,
  postMarketingCase,
  postSign,
  postSignRemuneration,
  trackAlternateCta,
} from '../axios/requests'
import { extractUserDataForSign } from '../utils/redux'
import { useDispatch, useSelector } from 'react-redux'
import { selectForm, selectFormKey } from '../redux/form/form.selectors'
import { useHistory, useLocation } from 'react-router-dom'
import { selectAuthKey } from '../redux/auth/auth.selectors'
import { AUTH } from '../redux/auth/auth.keys'
import { setAuthKey } from '../redux/auth/auth.actions'
import { UI } from '../redux/ui/ui.keys'
import { resetFormValidation, setUiKey } from '../redux/ui/ui.actions'
import { selectIsAllowedToGoFromThirdStep, selectUiKey } from '../redux/ui/ui.selectors'
import { FORM } from '../redux/form/form.keys'
import { ALLOWED_PATH } from '../constants/allowed-path'
import useModal from './useModal'
import { closeAndResetModal } from '../redux/modal/modal.actions'
import { trackJSErrorLog } from '../utils/utils'
import { pushEventToOptimizely } from '../utils/optimizely'

let apiSignCallCounter = 0
let apiGetAuthCallCounter = 0

const useGoToAttachment = (errorCallback) => {
  const isAllowedToGoFromThirdStep = useSelector(selectIsAllowedToGoFromThirdStep)
  const data = useSelector(selectForm)
  const secret = useSelector(selectUiKey(UI.SECRET))
  const insuranceType = useSelector(selectAuthKey(AUTH.INSURANCE_TYPE))
  const isSwitched = useSelector(selectFormKey(FORM.IS_SWITCHED))
  const allowedPath = useSelector(selectAuthKey(AUTH.ALLOWED_PATH))
  const ctaButtonText = useSelector(selectUiKey(UI.CTA_BUTTON_TEXT))
  const caseIdentifier = useSelector(selectUiKey(UI.CASE_IDENTIFIER))
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { openConfirmAssignUsJob, openFuckUpModal } = useModal()

  const postSignAction = async (shouldCloseModal) => {
    const signPayload = extractUserDataForSign({
      data,
      isSwitched,
      isAragSwitched: insuranceType === 'arag',
      secret,
      isRemuneration: allowedPath === ALLOWED_PATH.REMUNERATION,
    })
    console.log('BINGOOOOOOOO', signPayload)
    apiSignCallCounter++
    const response =
      allowedPath === ALLOWED_PATH.REMUNERATION ? await postSignRemuneration(signPayload) : await postSign(signPayload)
    if (response.status !== 204) {
      if (apiSignCallCounter < 3) {
        setTimeout(async () => {
          await postSignAction()
        }, 3000)
      } else {
        handleErrorCase(response)
      }
    } else {
      await getAuthAction(shouldCloseModal)
    }
  }

  const getAuthAction = async (shouldCloseModal) => {
    apiGetAuthCallCounter++
    const { status, data } = await getAuthenticate(secret)
    if (data.allowedPath && data.allowedPath === ALLOWED_PATH.ATTACHMENT) {
      handleSuccessCase(shouldCloseModal)
    } else {
      if (apiGetAuthCallCounter < 3) {
        setTimeout(async () => {
          const allowedPath = data.allowedPath || 'UNKNOWN'
          const errorMessage = `CRM has not updated allowed path yet. [allowedPath=${allowedPath}, status=${status}, count=${apiGetAuthCallCounter}]`
          trackJSErrorLog(errorMessage)
          await getAuthAction(shouldCloseModal)
        }, 3000)
      } else {
        handleErrorCase()
      }
    }
  }

  const handleErrorCase = () => {
    openFuckUpModal()
    dispatch(setAuthKey(AUTH.CASE_SIGNED, false))
    dispatch(setUiKey(UI.IS_SIGN_IN_PROGRESS, false))
    dispatch(resetFormValidation())
    dispatch(setUiKey(UI.CURRENT_STEP, 1))
    trackJSErrorLog('Fail to get authentication')
  }

  const handleSuccessCase = (shouldCloseModal) => {
    if (shouldCloseModal) {
      dispatch(closeAndResetModal())
    }
    const urlSearchParam = new URLSearchParams(location.search)
    urlSearchParam.delete('secret')
    const search = urlSearchParam.toString()
    dispatch(setUiKey(UI.IS_SIGN_IN_PROGRESS, false))
    dispatch(resetFormValidation())
    history.push(`/attachment/${secret}${search ? '?'.concat(search) : ''}`)
    dispatch(setUiKey(UI.CURRENT_STEP, 1))
  }

  const marketing = async () => {
    const payload = getMarketingPayload()
    if (payload) {
      await postMarketingCase(caseIdentifier, payload)
    }
  }

  const getMarketingPayload = () => {
    const url = new URL(window.location.href)
    const searchParams = new URLSearchParams(url.search)
    const testVariation = searchParams.get('test_variation')
    const testSpecs = searchParams.get('test_specs')
    if (testVariation || testSpecs) {
      const payload = {}
      if (testVariation) Object.assign(payload, { ['emailTestVariation']: testVariation })
      if (testSpecs) Object.assign(payload, { ['emailTestSpecs']: testSpecs })
      return payload
    } else {
      return null
    }
  }

  const signLead = async (shouldCloseModal) => {
    dispatch(setUiKey(UI.IS_UPDATE_STEP_FAILED, false))
    dispatch(setUiKey(UI.IS_SIGN_IN_PROGRESS, true))
    dispatch(setAuthKey(AUTH.CASE_SIGNED, true))
    await marketing()
    await postSignAction(shouldCloseModal)
    if (window.useAlternateCta) {
      trackAlternateCta(secret, {
        clicked_second_cta: 1,
      })
    }
  }

  return async () => {
    if (isAllowedToGoFromThirdStep) {
      if (ctaButtonText === 'app.button.button_submit_assign_us') {
        openConfirmAssignUsJob(signLead.bind(null, true))
      } else {
        await signLead()
      }
    } else {
      dispatch(setUiKey(UI.IS_UPDATE_STEP_FAILED, true))
      errorCallback()
    }
  }
}

export default useGoToAttachment
